// import * as moment from 'moment';
import BaseModule from '@/utils/BaseFireVuex';

/* eslint-disable max-classes-per-file */

class Booth {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.links = data.links;
    this.html = data.html;
  }

  toString() {
    return this.name;
  }
}

const boothConverter = {
  toFirestore(booth) {
    return {
      name: booth.name,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new Booth(data);
  },
};

const schema = {
  name: {
    type: 'string',
    required: true,
    // validator: (rule, value) => value === 'muji',
  },
};

const options = {
  name: 'booth',
  path: 'booth',
  schema,
  converter: boothConverter,
  parentModule: 'event',
  parentIdField: 'eventId',
};

class BoothModule extends BaseModule {
  /* eslint-disable max-len */
  getters() {
    return {
      ...super.getters(),
      currentPath: (state, getters, rootState) => {
        const path = `account/${rootState.account.current.id}/event/${rootState.event.current.id}/booth/${rootState.booth.current.id}`;
        return path;
      },
    };
  }
}

export default new BoothModule(options).getModule();
/* eslint-enable max-classes-per-file */

import * as moment from 'moment';
import BaseModule from '@/utils/BaseFireVuex';

/* eslint-disable max-classes-per-file */
/*
class Event {
  constructor(data) {
    this.id = data.id;
    this.title = data.title;
    this.date = data.date;
  }

  toString() {
    return this.title;
  }
}
*/

const eventConverter = {
  toFirestore(event) {
    return {
      title: event.title,
      date: event.date,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    data.date = moment(data.date.toDate());
    data.id = snapshot.id;
    return data;
  },
};

const schema = {
  title: {
    type: 'string',
    required: true,
    // validator: (rule, value) => value === 'muji',
  },
  date: {
    type: 'date',
    required: true,
  },
};

const options = {
  name: 'event',
  path: 'event',
  schema,
  converter: eventConverter,
};

/* eslint-disable no-unused-vars */
class EventModule extends BaseModule {
  getters() {
    return {
      ...super.getters(),
      currentPath: (state, getters, rootState) => {
        console.log(rootState.event.current.id);
        return `account/${rootState.account.current.id}/event/${rootState.event.current.id}`;
      },
    };
  }
}

export default new EventModule(options).getModule();
/* eslint-enable max-classes-per-file */

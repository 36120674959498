import Vue from 'vue';
import Vuex from 'vuex';
import event from '@/store/modules/event';
import stage from '@/store/modules/stage';
import agenda from '@/store/modules/agenda';
import account from '@/store/modules/account';
import auth from '@/store/modules/auth';
import people from '@/store/modules/people';
import booth from '@/store/modules/booth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    event,
    stage,
    agenda,
    auth,
    people,
    account,
    booth,
  },
});

// import * as moment from 'moment';
import BaseModule from '@/utils/BaseFireVuex';
import axios from 'axios';
import { auth, storage } from '@/firebase';

/* eslint-disable max-classes-per-file */

class People {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.lastName = data.lastName;
    this.event = data.event;
    this.password = data.password;
    this.type = data.type;
    this.organization = data.organization;
    this.phone = data.phone;
    this.location = data.location;
    this.certificate = data.certificate;
  }

  toString() {
    return this.name;
  }
}

const PeopleConverter = {
  toFirestore(people) {
    console.log(people);
    const type = typeof people.type !== 'undefined' ? people.type : null;
    const location = typeof people.location !== 'undefined' ? people.location : null;
    const organization = typeof people.organization !== 'undefined' ? people.organization : null;
    const phone = typeof people.phone !== 'undefined' ? people.phone : null;
    const lastName = typeof people.lastName !== 'undefined' ? people.lastName : null;
    const certificate = typeof people.certificate !== 'undefined' ? people.certificate : null;
    /*
    if (typeof people.type !== 'undefined') {
      type = people.type;
    }
    */
    return {
      name: people.name,
      email: people.email,
      password: people.password,
      event: people.event,
      certificate,
      lastName,
      location,
      organization,
      phone,
      type,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new People(data);
  },
};

const schema = {
  name: {
    type: 'string',
    required: true,
    // validator: (rule, value) => value === 'muji',
  },
  email: {
    type: 'email',
    required: true,
  },
  password: {
    type: 'string',
    required: true,
  },
  event: {
    type: 'string',
    required: true,
  },
  phone: {
    type: 'string',
    required: false,
  },
  organization: {
    type: 'string',
    required: false,
  },
  location: {
    type: 'string',
    required: false,
  },
  type: {
    type: 'string',
    required: true,
  },
};

const options = {
  name: 'people',
  path: 'invitations',
  schema,
  converter: PeopleConverter,
  parentModule: 'event',
  parentIdField: 'eventId',
};

class PeopleModule extends BaseModule {
  getters() {
    return {
      ...super.getters(),
      currentPath: (state, getters, rootState) => `account/${rootState.account.current.id}/event/${rootState.event.current.id}/invitations/${rootState.people.current.id}`,
    };
  }

  actions() {
    return {
      ...super.actions(),
      logout() {
        localStorage.removeItem('t');
        return auth().signOut();
      },
      async getCertificateUrl(context) {
        if (!context.state.current.certificate) {
          await axios({
            method: 'post',
            url: `${process.env.VUE_APP_BASE_URL}/certificate`,
            data: {
              path: context.getters.currentPath,
            },
          }).catch((err) => {
            console.log(err);
          });
        }
        const ref = storage().ref(`/certificates/${context.state.current.id}.pdf`);
        await ref.getDownloadURL().then(async (url) => axios.get(url, { responseType: 'blob' })
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${context.state.current.id}.pdf`;
            link.click();
            URL.revokeObjectURL(link.href);
          }).catch(console.error));
      },

    };
  }
}

export default new PeopleModule(options).getModule();
/* eslint-enable max-classes-per-file */

// import * as moment from 'moment';
import BaseModule from '@/utils/BaseFireVuex';
import * as fb from '@/firebase';
import _ from 'lodash';

/* eslint-disable max-classes-per-file */
/* eslint-disable max-len */

class Agenda {
  constructor(data) {
    this.id = data.id;
    this.title = data.title;
    this.start = data.start;
    this.end = data.end;
    this.speakers = data.speakers;
    this.description = data.description;
    this.bgImg = data.bgImg;
    this.isLive = data.isLive;
    this.sponsorLogo = data.sponsorLogo;
    this.isBreak = data.isBreak;
  }

  toString() {
    return this.title;
  }
}

const agendaConverter = {
  toFirestore(agenda) {
    return {
      title: agenda.title,
      start: agenda.start,
      end: agenda.end,
      bgImg: agenda.bgImg,
      isLive: agenda.isLive ? agenda.isLive : null,
      description: agenda.description ? agenda.description : null,
      isBreak: agenda.isBreak,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    data.start = data.start.toDate();
    data.end = data.end.toDate();
    /*
    if (!moment.isMoment(data.start)) {
      data.start = moment(data.start.toDate());
      data.end = moment(data.end.toDate());
    }
    */

    data.id = snapshot.id;
    return new Agenda(data);
  },
};

const schema = {
  title: {
    type: 'string',
    required: true,
    // validator: (rule, value) => value === 'muji',
  },
  start: {
    type: 'date',
    required: true,
  },
  end: {
    type: 'date',
    required: true,
  },
  description: {
    type: 'string',
    required: false,
  },
  bgImg: {
    type: 'string',
    required: false,
  },
  isLive: {
    type: 'boolean',
    required: false,
  },
  isBreak: {
    type: 'boolean',
    required: false,
  },
};

const options = {
  name: 'agenda',
  path: 'session',
  schema,
  converter: agendaConverter,
};

class AgendaModule extends BaseModule {
  getters() {
    return {
      ...super.getters(),
      list: (state) => _.sortBy(state.items, 'start'),
      liveSession: (state) => _.find(state.items, { isLive: true }),
      currentPath: (state, getters, rootState) => `account/${rootState.account.current.id}/event/${rootState.event.current.id}/stage/${rootState.stage.current.id}/session`,
    };
  }

  actions() {
    return {
      ...super.actions(),
      async makeLive(context, sessionId) {
        const path = this.getters['agenda/currentPath'];
        let qs = null;

        await fb.db
          .collection(path)
          .withConverter(agendaConverter)
          .get()
          .then(async (querySnapshot) => {
            qs = querySnapshot;
          });

        const batch = fb.db.batch();
        await qs.forEach(async (doc) => {
          await batch.update(doc.ref, { isLive: false });
          // console.log(doc);
          const agendaItem = doc.data();
          agendaItem.isLive = false;
          context.commit('addItem', agendaItem);
        });

        await batch.commit();
        await this.dispatch('agenda/patch', { id: sessionId, path, data: { isLive: true } });
      },
      async bgImgUpload(context, payload) {
        const { data } = payload;
        const storageRef = fb
          .storage()
          .ref()
          .child(`/backgroundImages/${data.name}`);

        return storageRef.put(data).then((snapshot) => snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(downloadURL);
          return downloadURL;
        }));
      },
    };
  }
}

export default new AgendaModule(options).getModule();
/* eslint-enable max-classes-per-file */

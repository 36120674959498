import * as moment from 'moment';
import BaseModule from '@/utils/BaseFireVuex';

/* eslint-disable max-classes-per-file */

class Stage {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.date = data.date;
    this.eventId = data.eventId;
  }

  toString() {
    return this.title;
  }
}

const stageConverter = {
  toFirestore(stage) {
    return {
      name: stage.name,
      date: stage.date,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    data.date = moment(data.date.toDate());
    data.id = snapshot.id;
    return new Stage(data);
  },
};

const schema = {
  name: {
    type: 'string',
    required: true,
    // validator: (rule, value) => value === 'muji',
  },
  date: {
    type: 'date',
    required: true,
  },
};

const options = {
  name: 'stage',
  path: 'stage',
  schema,
  converter: stageConverter,
  parentModule: 'event',
  parentIdField: 'eventId',
};

class StageModule extends BaseModule {
  /* eslint-disable max-len */
  getters() {
    return {
      ...super.getters(),
      currentPath: (state, getters, rootState) => {
        const path = `account/${rootState.account.current.id}/event/${rootState.event.current.id}/stage/${rootState.stage.current.id}`;
        console.log(path);
        return path;
      },
    };
  }

  actions() {
    return {
      ...super.actions(),
      customAction(context) {
        console.log(context);
      },
    };
  }
}

export default new StageModule(options).getModule();
/* eslint-enable max-classes-per-file */
